// todo: We will need to remove the secrets from here that we are not using

export const firebaseConfigProd = {
    apiKey: 'AIzaSyDc-6ADSMJ-mc5Bluuw5v6Mfjc647BkfCM',
    authDomain: 'zigzag-support-portal-prod.firebaseapp.com',
    projectId: 'zigzag-support-portal-prod',
    storageBucket: 'zigzag-support-portal-prod.firebasestorage.app',
    messagingSenderId: '308401825687',
    appId: '1:308401825687:web:99b8118a07abb2a75fc6d2',
    measurementId: 'G-E3FGY0ZGN3',
    useFirebaseAuthMock: false,
};

export const firebaseConfigDev = {
    apiKey: 'AIzaSyBYr2uBJPyIv3JuSM3fVBOMhkE6dP-uImA',
    authDomain: 'zigzag-support-portal-dev.firebaseapp.com',
    projectId: 'zigzag-support-portal-dev',
    storageBucket: 'zigzag-support-portal-dev.firebasestorage.app',
    messagingSenderId: '1002235646180',
    appId: '1:1002235646180:web:5b8cdb9a18ae7a0a229cf1',
    useFirebaseAuthMock: false,
};

export const firebaseConfigCi = {
    apiKey: 'AIzaSyBYr2uBJPyIv3JuSM3fVBOMhkE6dP-uImA',
    authDomain: 'zigzag-support-portal-dev.firebaseapp.com',
    projectId: 'zigzag-support-portal-dev',
    storageBucket: 'zigzag-support-portal-dev.firebasestorage.app',
    messagingSenderId: '1002235646180',
    appId: '1:1002235646180:web:5b8cdb9a18ae7a0a229cf1',
    useFirebaseAuthMock: true,
};
