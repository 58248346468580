@if (vm$ | async; as vm) {
    <ion-content class="page-ion-content ion-padding">
        <app-chat-tags></app-chat-tags>
        <section class="user-information-container">
            @if (vm.dogName) {
                <p class="unset-margin"><b>Dog Name:</b> {{ vm.dogName }}</p>
            }
            @if (vm.ownerName) {
                <p class="unset-margin"><b>Owner Name:</b> {{ vm.ownerName }}</p>
            }
            @if (vm.breed) {
                <p class="unset-margin"><b>Breed:</b> {{ vm.breed }}</p>
            }
            @if (vm.breedType) {
                <p class="unset-margin"><b>Breed Group:</b> {{ vm.breedType }}</p>
            }
            @if (vm.gender) {
                <p class="unset-margin"><b>Gender:</b> {{ vm.gender }}</p>
            }

            @if (vm.age) {
                <p class="unset-margin"><b>Age:</b> {{ vm.age }} weeks old</p>
            }
            @if (!vm.hasArrived && !!vm.weeksTillArrival) {
                <p class="unset-margin"><b>Weeks till arrival:</b> {{ vm.weeksTillArrival }}</p>
            }
            @if (vm.countryCode) {
                <p class="unset-margin"><b>Country Code:</b> {{ vm.countryCode }}</p>
            }
        </section>
    </ion-content>
}
